.Signup {
  background-color: rgba(255, 255, 255, 1);
}

.signup-left-background {
  background-image: url(https://wholesale-ls-marketing.s3.amazonaws.com/Christian/Signup-Gradient.png);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.signup-left img {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.signup-form {
  margin: 150px auto;
  max-width: 360px;
}

.signup-form h1 {
  font-size: 25px;
  margin-bottom: 40px;
}

.signup-form p {
  font-size: 12px !important;
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: rgba(106, 119, 137, 1);
  line-height: 18px;
  margin-bottom: 7px;
  margin-top: 15px;
}

.signup-form .form-control {
  max-height: 20px !important;
  border: 1px solid #d2dae2 !important;
}

.signup-form .form-group {
  margin-bottom: 0px !important;
}

.signup-form .control-label {
  font-size: 12px !important;
}

.col-highlights {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 2px;
  padding-right: 2px;
}

.signup-selector {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgba(27, 27, 27, 1) !important;
  font-family: "Roboto", Helvetica, Arial, serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  border: none;
  box-shadow: 0 0 4px 1px #ececec;
}

.signup-selector:focus {
  outline: none !important;
  border-color: #1bbb5b;
}

.signup-selector:hover {
  border: 2px solid rgb(231, 231, 231);
}

.signup-selector-active {
  border: 2px solid #1bbb5b;
}

.signup-button {
  width: 100%;
  border-radius: 5px;
}

.signup-button:hover {
  border: none;
}

.login-button {
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  border-color: lightgray;
  color: rgb(59, 59, 59);
  font-weight: normal;
}

.login-button:hover {
  border-color: #1bbb5b;
  background-color: #1bbb5b;
}

.login-separator {
  border-bottom: 1px solid rgb(224, 224, 224);
}

/* .signup-bottons:visited {
  outline: blue;
} */

/* .Signup h1 {
  font-family: "TradeGothic";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  margin-top: 50px;
}

.Signup p {
  width: auto;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  color: #5b6a87;
  line-height: 1.8em;
  max-width: 600px;
  margin: auto;
}

.form-wrapper {
  width: 100%;
  max-width: 684px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(17, 49, 96, 0.08) 0px 1px 3px,
    rgba(17, 49, 96, 0.08) 0px 0px 1px;
  padding: 32px;
  border-radius: 5px;
  margin: 50px auto;
  margin-bottom: 100px;
  margin-top: 0px;
  text-align: left;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.title {
  font-size: 18px !important;
}

.form-wrapper p {
  margin-bottom: 15px;
  font-size: 16px;
}

.form-group {
  margin-top: 15px;
  margin-bottom: 0px;
}

.control-label {
  float: left;
}

.form-control {
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: #132042;
  font: normal 15px Open Sans, Arial, sans-serif;
  font-size: 15px !important;
  letter-spacing: 0.1px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d2dae2 !important;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(17, 49, 96, 0.06);
  -webkit-transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, padding 0.12s linear, max-height 0.12s linear,
    -webkit-box-shadow 0.12s linear;
  -webkit-transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  min-height: 45px;
  padding-left: 15px;
}

.auth__link {
  padding-top: 10px;
  text-align: left;
}

.auth__link {
  color: rgb(93, 157, 245);
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  display: block;
  margin-top: 40px;
  text-align: left;
  padding-top: 0px;
}

.btn-primary {
  padding: 15px 30px;
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-transform: none;
  background: #1bbb5b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-weight: 100;
  width: 100%;
  font-size: 18px;
  margin-top: 15px;
}

.btn-primary:disabled {
  opacity: 0.5;
}

textarea {
  height: 100px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.progress-container {
  width: 100%;
  height: 15px;
  border: 1px solid #1bbb5b;
  max-width: 500px;
  margin: auto;
}

.progress-bar {
  height: 100%;
  background-color: #1bbb5b;
  transition: 0.5s all ease;
} */
