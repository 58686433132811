/* General Styling */

h1,
h2,
h3,
p {
  font-family: "Open Sans", Helvetica, Arial, serif;
}

a {
  color: #7f8fa4;
  font-size: 14px;
  text-decoration: none;
}

b {
  font-weight: 600;
}

p {
  font-size: 14px;
  color: #27313e;
  text-align: left;
  margin: 0;
}

.backLink img {
  height: 13px;
  margin-right: 13px;
}

.lightText {
  color: #6c7b91;
  font-family: "Helvetica Neue";
}

.midBold {
  font-size: 17px;
  font-weight: 600;
}

/* View Property Styling */

.ViewProperty {
  margin: 18px;
  background-color: white;
}

.ViewProperty .row {
  margin-top: 40px;
}

/******* Top Section *******/

.ViewProperty__Wrapper {
  margin-top: 45px;
}

.ViewProperty__Header {
  margin-top: 40px;
}

.ViewProperty__Header h2 {
  color: #27313e;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.13px;
}

.ViewProperty__Header p {
  color: #27313e;
  font-size: 15px;
  letter-spacing: -0.09px;
}

.ViewProperty__Address {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.ViewProperty__Address > img {
  height: 13px;
  margin-right: 13px;
}

.ViewProperty__Address > p {
  font-size: 15px;
}

.ViewProperty__Row-Highlights {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 22px;
}

.ViewProperty__Row-Highlights > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  border-radius: 4px;
  padding: 8px 15px;
  background-color: #eff3fa;
  font-size: 14px;
  font-weight: 600;
  color: #27313e;
}

/******* View Property Card - Left Panel *******/

.ViewPropertyCard {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.ViewPropertyCard__Separator {
  background-color: #e6e6e6;
  margin: 0 0 0 25px;
}

.ViewPropertyCard__Image-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ViewPropertyCard__Image-Container #slider {
  height: 340px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ViewPropertyCard__Image-Highlight {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 7px 12px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 17px;
  z-index: 1;
  background-color: #ffffff;
}

.ViewPropertyCard__Details {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 0 11px 5px rgba(234, 234, 234, 0.5);
  width: 100%;
  text-align: center;
}

.ViewPropertyCard__Details .CardContainer {
  padding: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ViewPropertyCard__Contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ViewPropertyCard__ContactProfilePic {
  float: left;
}

.ViewPropertyCard__ContactProfile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ViewPropertyCard__ContactButton {
  padding: 8px 35px;
}

.ViewPropertyCard__ContactInfo {
  font-size: 16px;
  margin-left: 15px;
}
.ViewPropertyCard__PriceBreakdown {
  width: 100%;
}

.ViewPropertyCard__PriceBreakdown tr > td:first-child {
  color: #909dad;
  font-size: 15px;
  text-align: left;
}

.ViewPropertyCard__PriceBreakdown tr > td:last-child {
  font-size: 17px;
  font-weight: 600;
  text-align: right;
}

.ViewPropertyCard__PriceBreakdown tr:not(:last-child) td {
  padding-bottom: 10px;
}

.ViewPropertyCard__EstimatedReturns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ViewPropertyCard__EstimatedReturns p {
  color: #fe6500;
}

.ViewPropertyCard__PercentageReturns {
  font-size: 14px;
  border-radius: 2.5px;
  background-color: #fe6500;
  color: #fff;
  padding: 2px 12px;
  margin-right: 22px;
  font-weight: 600;
}

/******* View Property Details - Right Panel *******/

.ViewProperty__Details h3 {
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 17px;
  margin: 0 0 30px 0;
}

.ViewProperty__DetailsTable {
  width: 100%;
  margin: 30px 0;
}

.ViewProperty__DetailsTable tr > td {
  padding-bottom: 15px;
}

.ViewProperty__DetailsTable tr > td:last-child {
  color: #6c7b91;
}

.ViewProperty__Title {
  margin-top: 20px;
}

.ViewProperty-Highlights p {
  color: black !important;
  font-family: "SourceSansPro-Semibold", Helvetica, Arial, serif !important;
  font-size: 11.2px !important;
  letter-spacing: -0.07px !important;
  line-height: 14px !important;
  margin-top: 10px;
  text-align: center;
}

.ViewProperty__Desc {
  margin-bottom: 32px;
  white-space: break-spaces;
}

.ViewProperty__Desc h3 {
  margin-bottom: 22px;
}

.ViewProperty__MapContainer {
  width: 100%;
  height: 360px;
}

/******* Full Screen Slider *******/
.FullScreenSlider {
  width: 100vw;
  height: auto;
  background: rgba(83, 83, 83, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.FullScreenSlider #slider {
  z-index: 11;
}

.FullScreenSlider #slide-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
}

.FullScreenSlider #slide-wrapper > div {
  width: 90%;
  height: 85%;
}

/******* Media Queries (Responsive) *******/
@media only screen and (min-width: 992px) {
  .row.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
  }

  .ViewPropertyCard {
    width: 90%;
    position: sticky;
    top: 40px;
  }
}
