/******* Properties Card Popup (On Mapbox) *******/

.PropertyCardPopup {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 3px solid #ffffff00;
  transition: background-color 300ms ease, box-shadow 300ms ease;
}

.PropertyCardPopup__Details {
  height: auto;
  width: 280px;
  padding-left: 12px;
}

.PropertyCardPopup__Title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  height: 3em;
  line-height: 1.5em;
  overflow: hidden;
}

.PropertyCardPopup__Address {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}

.PropertyCardPopup__Address > img {
  height: 13px;
  margin-right: 8px;
}

.PropertyCardPopup__Address > p {
  font-size: 14px;
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
}
