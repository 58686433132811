/******* Dashboard *******/

.Dashboard {
  clear: both;
  padding-bottom: 90px;
  overflow: auto;
}

.Dashboard__Results-Found {
  margin-top: 20px;
  color: #7f8fa4;
  font-size: 13px;
}

.Dashboard h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 35px;
}

.Dashboard > div {
  padding-left: 0;
}
