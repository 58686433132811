/******* Message Conversation *******/

.MessageConvo {
  width: 100%;
  position: relative;
}

.MessageConvo__Head {
  margin: 20px 25px 30px;
}

.MessageConvo__Separator {
  border-bottom: 1px solid rgb(236, 236, 236);
}

.MessageConvo__Messages,
.MessageConvo__CreateConvo {
  padding: 5px 25px;
}

/******* Create New Conversation *******/

.MessageConvo__Users {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.MessageConvo__Selected-User {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 15px;
  margin: 8px 10px 8px 0;
  padding: 4px 9px;
  background: rgb(236, 236, 236);
}

.MessageConvo__Input-Container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  padding: 0 25px 65px;
}

.MessageConvo__Input-Container > textarea {
  height: 100%;
  width: 100%;
  resize: none;
  border-radius: 5px;
  margin-bottom: 3px;
  border-color: #d8d8d8;
  padding: 7px 11px;
}

/******* Media Queries (Responsive) *******/

@media only screen and (min-width: 767px) {
}
