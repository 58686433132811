/******* Properties Card (Portrait) *******/

.PropertyCard {
  margin: 14px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  border: 3px solid #ffffff00;
  transition: background-color 200ms ease, box-shadow 200ms ease;
  box-shadow: 0 0 5px 0 rgba(230, 230, 230, 0.5);
  background-color: #fff;
}

.PropertyCard.selected {
  border: 3px solid #646464;
}

.PropertyCard:hover {
  box-shadow: 2px 2px 10px #a0a0a090;
}

.PropertyCard__Title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  height: 3em;
  line-height: 1.5em;
  overflow: hidden;
}

.PropertyCard__Image-Container {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 15px;
}

.PropertyCard__Image-Container > img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.PropertyCard__Details {
  width: 100%;
  height: auto;
  padding: 0;
}

.PropertyCard__Address {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}

.PropertyCard__Address > img {
  height: 13px;
  margin-right: 8px;
}

.PropertyCard__Address > p {
  font-size: 14px;
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
}

.PropertyCard__Row-Highlights {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
}

.PropertyCard__Highlight {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-right: 9px;
  border-radius: 4px;
  padding: 6px 12px;
  background-color: #eff3fa;
  font-size: 12px;
  font-weight: 600;
  color: #27313e;
}

.PropertyCard__Highlight > p {
  font-size: 12px;
}

.PropertyCard__Highlight > img {
  width: 20px;
  height: auto;
  margin-right: 8px;
}

.PropertyCard__Row-Pricing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}

.PropertyCard__Row-Pricing-Item {
  margin-right: 9px;
  border-radius: 2px;
  padding: 8px 14px;
  font-size: 10px;
  font-weight: 600;
  color: #27313e;
  background-color: #f1f1f1;
  white-space: nowrap;
  overflow: hidden;
}

.PropertyCard__Row-Pricing-Item p:first-child {
  color: #7f8fa4;
}

.PropertyCard__Row-Pricing-Item--Profit {
  background-color: #ffe8d9;
}

.PropertyCard__Row-Pricing-Item--Profit p:first-child {
  color: #fe6500;
}

/******* Propertie Card (Landscape) *******/

.PropertyCard--Landscape {
  flex-direction: row;
  padding: 8px;
  margin: 8px 0;
}

.PropertyCard__Image-Container--Landscape {
  width: 280px;
  margin-bottom: 0;
}

.PropertyCard__Details--Landscape {
  height: 200px;
  width: 320px;
  padding-left: 12px;
}

/******* Media Queries (Responsive) *******/
/* @media only screen and (max-width: 992px) {
  .PropertyCard {
    flex-direction: column;
    box-shadow: 0 0 5px 0 rgba(230, 230, 230, 0.5);
    background-color: #fff;
    padding: 10px;
  }

  .PropertyCard__Image-Container {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 5px;
  }

  .PropertyCard__Details {
    width: 100%;
    height: auto;
    padding: 0;
  }
} */
