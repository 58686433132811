.MultiForm {
  margin: 18px;
  background-color: white;
}

.MultiForm__Wrapper {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.MultiForm__Title {
  margin-bottom: 20px;
}

.MultiForm__Title h1 {
  font-size: 22px;
  text-align: center;
}

.MultiForm__Title h2 {
  font-size: 14px;
  text-align: center;
}

.MultiForm__Section {
  width: 100%;
  min-height: 600px;
}

.MultiForm__Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MultiForm__Row-3 > div {
  width: 32%;
}

.MultiForm__Row-2 > div {
  width: 48%;
}

.MultiForm__Upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}
.MultiForm__UploadIcon {
  width: 180px;
  height: auto;
}
.MultiForm__UploadBtn {
  padding: 15px 32px;
}

.MultiForm__ImgWrapper {
  width: 100%;
  object-fit: contain;
  margin: 15px 0;
  position: relative;
}
.MultiForm__ImgWrapper > img {
  width: 100%;
}

.MultiForm__ImgDeleteBtn {
  position: absolute;
  display: flex;
  top: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  background: #e2e2e2;
  transition: transform ease-in 0.1s;
}

.MultiForm__ImgDeleteBtn:hover {
  transform: scale(1.1);
}

/******* Progress Bar *******/
.progressbar-wrapper {
  width: 100%;
  margin: 5px 0 35px 0;
}
.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 24px;
  height: 24px;
  content: "";
  border: 1px solid #cfcfcf;
  display: block;
  text-align: center;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.progressbar li:after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #cfcfcf;
  top: 12px;
  left: -50%;
  z-index: 0;
}

/* Stage - Active */
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  font-weight: bold;
}
.progressbar li.active:before {
  border-color: #5bc33e;
  background: #5bc33e;
}
.progressbar li.active + li:after {
  background-color: #5bc33e;
}
.progressbar li.active span:before {
  width: 12px;
  height: 12px;
  content: "";
  position: absolute;
  top: 6px;
  left: calc(50% - 6px);
  display: block;
  text-align: center;
  border-radius: 50%;
  z-index: 3;
  background-color: #fff;
}

/* Stage - Complete */
.progressbar li.complete:before {
  border-color: #5bc33e;
  background: #5bc33e;
}
.progressbar li.complete + li:after {
  background-color: #5bc33e;
}
.progressbar li img {
  display: none;
}
.progressbar li.complete img {
  display: block;
  position: absolute;
  top: 6px;
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
  z-index: 4;
}

/******* Form Controls *******/
.MultiForm__AutoComplete {
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: #132042;
  font: normal 15px Open Sans, Arial, sans-serif;
  font-size: 15px !important;
  letter-spacing: 0.1px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d2dae2 !important;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(17, 49, 96, 0.06);
  min-height: 36px;
  padding-left: 15px;
}

.MultiForm__AutoComplete-Suggestions {
  display: block;
  width: 100%;
  margin-bottom: 0;
  padding-top: 5px;
  color: #132042;
  font: normal 15px Open Sans, Arial, sans-serif;
  font-size: 15px !important;
  letter-spacing: 0.1px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d2dae2 !important;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(17, 49, 96, 0.06);
  transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  min-height: 36px;
  padding-left: 15px;
}

.form-control {
  min-height: 36px !important;
  border: 1px solid #d2dae2 !important;
}

.control-label {
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: rgba(106, 119, 137, 1);
  line-height: 18px;
  margin-bottom: 7px;
  margin-top: 15px;
}

.MultiForm__btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  margin-right: 140;
}

.MultiForm__back-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #34901b;
  width: 130px;
  margin-right: 40px;
  background: none;
  transition: 0.25s linear;
}
.MultiForm__back-btn:hover {
  background: transparent;
  color: #34901b;
  box-shadow: none;
  transform: translateX(-4px);
}
.MultiForm__back-btn > img {
  width: 20px;
  height: 20px;
}

.MultiForm__btn {
  border-radius: 4px;
  background-color: #34901b;
  padding: 4px 60px;
}

.form-group.required .control-label:after,
label.form-group.required:after {
  content: "*";
  color: red;
}

/******* Media Queries (Responsive) *******/
@media only screen and (min-width: 992px) {
  .progressbar-wrapper {
    width: 90%;
    max-width: 1030px;
  }

  .MultiForm__Section {
    max-width: 720px;
  }

  .MultiForm__btn-container {
    margin-right: 18%;
  }
}
