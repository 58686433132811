:root {
    --main-color: #5ea3d0;
    --secondary-color: white;
    --main-text-color: #3e5869;
    --secondary-text-color: #b0c7d6;
    --send-message-form: #F5F5F5;
}

.chat-container {
    width: 1191px;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(214,214,214,0.5);
    margin-top: 40px;
}

.message-list {
    box-sizing: border-box;
    width: 100%;
    overflow: scroll;
    background: white;
}

.message {
    margin: 15px 0;
}

.message div:nth-child(1) {
    font-size: 11px;
    color: var(--main-text-color);
    opacity: 0.9;
    margin-bottom: 6px;
}
.message div:nth-child(2) {
    background: var(--main-color);
    color: var(--secondary-color);
    display: inline;
    padding: 4px 8px;
    border-radius: 8px;
}

.send-message-container {
    width: 1192px;
    background-color: #F9FAFB;
}

.send-message-form .form-control {
    margin-top: 50px;
    padding: 50px;
    box-sizing: border-box;
    border: 1px solid #7CBEF1;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 #D5E8F8;
}