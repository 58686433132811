/* General Styling */

.Index {
  background-color: rgba(248, 248, 251, 1);
}

.Breadcrumbs {
  background-color: #fff;
  border-top: 1px solid lightgrey;
  border-bottom: 0.5px solid lightgrey;
}
.breadcrumb {
  background-color: #fff !important;
  margin: 0px;
  padding: 15px 15px;
}

/******* Filter Bar Section *******/

.Properties__Filter {
  background-color: #fff;
  border-top: 1px solid lightgrey;
  border-bottom: 0.5px solid lightgrey;
}

.Properties__Filter-Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.Property__Filter-Label {
  font-size: 12px;
  padding-bottom: 2px;
  color: #7f8fa4;
  font-weight: 600;
  min-width: 140px;
}

.Properties__Filter-Container .form-group {
  margin: 8px 10px 14px 10px;
}

.Properties__Filter-Container .Dropdown-control {
  border: 1px solid #ced0da;
  border-radius: 4px;
  background: linear-gradient(0deg, #f2f4f7 0%, #ffffff 100%);
  font-weight: 600;
  padding: 8px 12px;
}

.Properties__Filter-Selection {
  display: flex;
}

.Properties__Filter-Selection div.row {
  margin: 0;
}
.Properties__Filter-Selection div.col-md-6 {
  padding: 0;
}

.Locations-Search-Bar {
  border-radius: 3px;
  width: 90%;
  position: relative;
  display: inline-block;
}

.Locations-Search-Bar__Wrapper {
  margin-top: 1.5em;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Locations-Search-Bar__Wrapper > input {
  border-radius: 4px;
  border: 1px solid #ced0da;
  box-shadow: none;
  padding: 8px;
  flex-grow: 1;
}

.Locations-Search-Bar__Wrapper > a {
  cursor: pointer;
  padding: 0 10px 0 10px;
}
.Locations-Search-Bar__Wrapper > a img {
  width: 10px;
  height: 10px;
}

.Locations-Search-Bar__Dropdown-Content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 290px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 540px;
  overflow: scroll;
}
.Locations-Search-Bar__Dropdown-Content.active {
  display: block;
}
.Locations-Search-Bar__Dropdown-Content a {
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.Locations-Search-Bar__Dropdown-Content a:hover {
  background: rgb(243, 243, 243);
}

/******* Property Search List *******/

.Properties {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 90vh;
}

.Properties__Results {
  padding: 0 15px;
  flex: 1 0;
  height: 100%;
  overflow-y: scroll;
}

.Properties__Results-Found {
  margin-top: 20px;
  color: #7f8fa4;
  font-size: 13px;
}

.Properties h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 35px;
}

/******* Map Box Container *******/

.Properties__MapContainer {
  width: 100%;
  height: 90vh;
  background-color: rgb(218, 218, 218);
}

.mapboxgl-ctrl-group {
  width: 30px;
  margin: 10px 0 0 10px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  width: 90%;
}

.markerImage {
  width: 26px;
}

.mapbox-point {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #4e4e4e;
}

.mapbox-point::after {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.mapbox-point.selected::after,
.mapbox-point:hover::after {
  background: #ff802c;
}

/******* Media Queries (Responsive) *******/
@media only screen and (min-width: 992px) {
  .Properties__Results {
    flex: 1 0 630px;
  }
}
@media only screen and (min-width: 767px) {
  .Properties__Filter-Container {
    flex-direction: row;
  }
  .Locations-Search-Bar {
    width: 330px;
  }
}
