@import url(/Users/kevingov/Documents/wholesale-ls-app-client/src/components/Colors.js);
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
html,
body {
  max-width: 100%;
  /* overflow-x: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  background-color: #fff !important;
}

.App .nav .active {
  color: #6c63ff;
}

.App .nav {
  top: 0;
  z-index: 9999;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 70px;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  font-weight: 400;
  white-space: nowrap;
}

.App .nav span {
  cursor: pointer;
}

.App .nav .nav-content {
  display: flex;
  max-width: 1180px;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  margin: auto;
  padding: 0px;
}

.App .nav .nav-content .logo {
  max-width: 100%;
  display: inline-block;
}

.App .nav .nav-content .nav-menu {
  display: flex;
  width: auto;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  align-self: auto;
  order: 0;
  flex: 1 1;
  text-decoration: none;
  white-space: nowrap;
}

.App .nav .nav-content .nav-menu.right {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.nav-menu .links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-menu .links > a {
  margin: 0 16px;
}

.link-10 {
  transition: all 0.2s ease;
  color: #132144;
  font-size: 15px;
  font-weight: 400;
}

.link-10:hover {
  text-decoration: none !important;
}

.seperator {
  width: 1px;
  height: 18px;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 20px;
  background-color: rgba(141, 162, 181, 0.33);
  margin: 0px 20px;
}

.conclusion {
  background-color: #33353a;
  color: #fff;
  margin-bottom: 0px;
  padding: 80px 0px;
  padding-bottom: 20px;
}

.conclusion h1 {
  font-family: "TradeGothic", sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
}

.conclusion p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 30px;
}

.footer-nav {
  display: flex;
  padding: 60px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #33353a;
  color: #fff;
}

.footer-nav-container {
  display: flex;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 35px 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: block;
  width: 1040px;
  margin-right: auto;
  margin-left: auto;
}

.sections-menu-headline {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  line-height: 26px;
  font-family: "TradeGothic", sans-serif;
  text-transform: uppercase;
}

.structure-menu-link {
  display: block;
  margin-bottom: 22px;
  transition: color 0.2s ease, transform 0.4s ease;
  color: #8792a1;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.structure-menu-link:hover {
  color: #fff;
  text-decoration: none;
}

.copy {
  display: flex;
  height: 60px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  background-color: #33353a;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.05)
  );
}

.copy-inner {
  font-size: 14px;
  font-weight: 500;
  color: hsla(0, 0%, 100%, 0.37);
  line-height: 26px;
}

.secondary-btn {
  color: #fff;
  background-color: #1bbb5b;
  border-radius: 3px;
  padding: 10px 20px;
  border: none;
}

.secondary-btn:hover,
.secondary-btn:active,
.secondary-btn:focus {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
  transition: 0.2s all ease;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

button:hover {
  color: #0085ef;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.other-btn {
  color: #444444;
  background: #f3f3f3;
  border: 1px #dadada solid;
  padding: 5px 10px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
  outline: none;
  cursor: pointer;
}

.other-btn:hover {
  border: 1px #c6c6c6 solid;
  box-shadow: 1px 1px 1px #eaeaea;
  color: #333333;
  background: #f7f7f7;
  text-decoration: none !important;
}

.App .nav:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  border-radius: 100px / 10px;
  right: 10px;
  left: auto;
  transform: skew(8deg) rotate(3deg);
}

textarea:focus,
input:focus {
  outline: none;
}

/******* Media Queries (Responsive) *******/
@media only screen and (min-width: 992px) {
  .nav-menu .links > a {
    margin: 0 22px;
  }
}

.btn {
  color: black;
  min-height: 42px;
  padding: 12px 20px 11px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  border-radius: 25px;
  background-color: transparent;
  transition: color 200ms ease, background-color 200ms ease;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  margin-top: 20px;
}

.btn:hover {
  background-color: #1bbb5b;
  color: white;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  color: #1bbb5b;
}
@-webkit-keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.loader {
  position: absolute;
  height: 100%;
  top: 50%;
  left: 50%;
}

.lander {
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1190px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.lander h1 {
  font-size: 42px;
  color: #434343;
  line-height: 1.44;
  margin: 0 0 0px;
  padding: 0;
  font-family: "Playfair Display", serif;
  font-weight: normal;
  margin-top: 40px;
}

.lander img {
  width: 45%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  color: white;
  min-height: 38px;
  padding: 12px 20px 11px;
  border-radius: 4px;
  border: none;
  background-color: #34901b;
  transition: color 200ms ease, background-color 200ms ease;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  margin-bottom: 20px;
}

.btn:hover {
  background-color: #55aa3e;
}

.btn.disabled {
  background-color: rgb(124, 124, 124);
}

.btn.disabled:hover {
  transform: none;
}

.highlight-container {
  /* background: #1bbb5b; */
  /* height: 250px;
    padding: 25px; */
}

.highlight-container h1 {
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.44;
  font-family: "Roboto", sans-serif;
  color: #697b93;
}

.highlight-container h3 {
  margin-top: 10px;
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  color: #697b93;
}

.cityCards {
  /* padding: 130px; */
  margin-top: 50px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1190px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-bottom: 1px solid #cbd2de;
  padding-bottom: 20px;
}

.cityCards h1 {
  color: white;
  text-align: center;
  font-family: blacklight;
  /* margin-bottom: 10px; */
  font-size: 45px;
}

.cityCards h2 {
  color: black;
  text-align: center;
  /* margin-bottom: 10px; */
  font-size: 30px;
}

.cityCards h4 {
  font-family: PlayfairDisplay;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0e2a4b;
}

.cityCards p {
  color: black;
  text-align: center;
}

.cityCards img {
  box-align: center;
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* border-top-right-radius: 25px;
        border-top-left-radius: 25px; */
  border-radius: 25px;
  min-height: 300px;
  height: 482px;
  margin-top: 20px;
  margin-bottom: 50px;
  background-blend-mode: darken;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.cityCards .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-us-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1190px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-bottom: 1px solid #cbd2de;
  padding-bottom: 50px;
}

.about-us-text {
  margin-top: 20px;
  margin-bottom: 50px;
}

.about-us-text h1 {
  position: relative;
  z-index: 1;
  font-family: Oswald, sans-serif;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.about-us-text h3 {
  margin-top: 10px;
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 10px;
  font-family: "Sorts Mill Goudy", sans-serif;
  color: #000;
}

.about-us-text p {
  margin-top: 0;
  margin-bottom: 10px;
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.about-us-text .title-underline {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 2px;
  max-width: 200px;
  background-color: #1bbb5b;
}

.about-us-text img {
  width: 80%;
}

.how-it-works {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1190px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  margin-top: 70px;
  margin-bottom: 70px;
}

.how-it-works .card {
  background-color: white;
  transition: all 0.5s ease-in-out;
  border-radius: 0.5rem;
  margin: 12px;
  border-color: white;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.253) !important;
  padding: 0px;
  border-radius: 25px;
}

.how-it-works .card-title {
  font-size: 1.8rem;
  font-weight: 300;
  font-family: "PT Serif", serif;
  line-height: 27px;
  padding: 23px;
  text-align: center;
  height: 100px;
}

.how-it-works .card-text {
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  font-family: "PT Serif", serif;
  padding: 23px;
  text-align: left;
  height: 186px;
}

.how-it-works .card-img {
  box-align: center;
  /* padding: 17px; */
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.how-it-works h1 {
  font-size: 13px;
  font-family: sans-serif;
  color: black;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.how-it-works h3 {
  margin-top: 10px;
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 10px;
  font-family: "Sorts Mill Goudy", sans-serif;
  color: #000;
}

.newsletter {
  position: relative;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #1bbb5b;
  margin-bottom: 100px;
}

.narrow-box {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1190px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.newsletter-box {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  width: 36%;
}

.email-collect {
  width: 50%;
  height: 47px;
  margin-bottom: 0px;
  padding-left: 15px;
  border-style: none solid none none;
  border-width: 1px;
  border-color: #000 #469e79 #000 #000;
  border-top-left-radius: 2.5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 13px;
}

.green-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 52%;
  height: 100%;
  background-color: #1bbb5b;
  transform: skew(-15deg, 0deg);
}

.image-bg {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 53%;
  background-image: url(https://wholesale-ls-marketing.s3.amazonaws.com/Modena-Newsletter-Home.jpg);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.Signup {
  background-color: rgba(255, 255, 255, 1);
}

.signup-left-background {
  background-image: url(https://wholesale-ls-marketing.s3.amazonaws.com/Christian/Signup-Gradient.png);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.signup-left img {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.signup-form {
  margin: 150px auto;
  max-width: 360px;
}

.signup-form h1 {
  font-size: 25px;
  margin-bottom: 40px;
}

.signup-form p {
  font-size: 12px !important;
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: rgba(106, 119, 137, 1);
  line-height: 18px;
  margin-bottom: 7px;
  margin-top: 15px;
}

.signup-form .form-control {
  max-height: 20px !important;
  border: 1px solid #d2dae2 !important;
}

.signup-form .form-group {
  margin-bottom: 0px !important;
}

.signup-form .control-label {
  font-size: 12px !important;
}

.col-highlights {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 2px;
  padding-right: 2px;
}

.signup-selector {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgba(27, 27, 27, 1) !important;
  font-family: "Roboto", Helvetica, Arial, serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  border: none;
  box-shadow: 0 0 4px 1px #ececec;
}

.signup-selector:focus {
  outline: none !important;
  border-color: #1bbb5b;
}

.signup-selector:hover {
  border: 2px solid rgb(231, 231, 231);
}

.signup-selector-active {
  border: 2px solid #1bbb5b;
}

.signup-button {
  width: 100%;
  border-radius: 5px;
}

.signup-button:hover {
  border: none;
}

.login-button {
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  border-color: lightgray;
  color: rgb(59, 59, 59);
  font-weight: normal;
}

.login-button:hover {
  border-color: #1bbb5b;
  background-color: #1bbb5b;
}

.login-separator {
  border-bottom: 1px solid rgb(224, 224, 224);
}

/* .signup-bottons:visited {
  outline: blue;
} */

/* .Signup h1 {
  font-family: "TradeGothic";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  margin-top: 50px;
}

.Signup p {
  width: auto;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  color: #5b6a87;
  line-height: 1.8em;
  max-width: 600px;
  margin: auto;
}

.form-wrapper {
  width: 100%;
  max-width: 684px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(17, 49, 96, 0.08) 0px 1px 3px,
    rgba(17, 49, 96, 0.08) 0px 0px 1px;
  padding: 32px;
  border-radius: 5px;
  margin: 50px auto;
  margin-bottom: 100px;
  margin-top: 0px;
  text-align: left;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.title {
  font-size: 18px !important;
}

.form-wrapper p {
  margin-bottom: 15px;
  font-size: 16px;
}

.form-group {
  margin-top: 15px;
  margin-bottom: 0px;
}

.control-label {
  float: left;
}

.form-control {
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: #132042;
  font: normal 15px Open Sans, Arial, sans-serif;
  font-size: 15px !important;
  letter-spacing: 0.1px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d2dae2 !important;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(17, 49, 96, 0.06);
  -webkit-transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, padding 0.12s linear, max-height 0.12s linear,
    -webkit-box-shadow 0.12s linear;
  -webkit-transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  min-height: 45px;
  padding-left: 15px;
}

.auth__link {
  padding-top: 10px;
  text-align: left;
}

.auth__link {
  color: rgb(93, 157, 245);
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  display: block;
  margin-top: 40px;
  text-align: left;
  padding-top: 0px;
}

.btn-primary {
  padding: 15px 30px;
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-transform: none;
  background: #1bbb5b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-weight: 100;
  width: 100%;
  font-size: 18px;
  margin-top: 15px;
}

.btn-primary:disabled {
  opacity: 0.5;
}

textarea {
  height: 100px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.progress-container {
  width: 100%;
  height: 15px;
  border: 1px solid #1bbb5b;
  max-width: 500px;
  margin: auto;
}

.progress-bar {
  height: 100%;
  background-color: #1bbb5b;
  transition: 0.5s all ease;
} */

.NotFound {
    padding-top: 100px;
    text-align: center;
  }
.Terms {
  padding: 50px 0px;
}

.Terms h1 {
  font-style: normal;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 30px;
}

.Terms p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
}

/* General Styling */

.Index {
  background-color: rgba(248, 248, 251, 1);
}

.Breadcrumbs {
  background-color: #fff;
  border-top: 1px solid lightgrey;
  border-bottom: 0.5px solid lightgrey;
}
.breadcrumb {
  background-color: #fff !important;
  margin: 0px;
  padding: 15px 15px;
}

/******* Filter Bar Section *******/

.Properties__Filter {
  background-color: #fff;
  border-top: 1px solid lightgrey;
  border-bottom: 0.5px solid lightgrey;
}

.Properties__Filter-Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.Property__Filter-Label {
  font-size: 12px;
  padding-bottom: 2px;
  color: #7f8fa4;
  font-weight: 600;
  min-width: 140px;
}

.Properties__Filter-Container .form-group {
  margin: 8px 10px 14px 10px;
}

.Properties__Filter-Container .Dropdown-control {
  border: 1px solid #ced0da;
  border-radius: 4px;
  background: linear-gradient(0deg, #f2f4f7 0%, #ffffff 100%);
  font-weight: 600;
  padding: 8px 12px;
}

.Properties__Filter-Selection {
  display: flex;
}

.Properties__Filter-Selection div.row {
  margin: 0;
}
.Properties__Filter-Selection div.col-md-6 {
  padding: 0;
}

.Locations-Search-Bar {
  border-radius: 3px;
  width: 90%;
  position: relative;
  display: inline-block;
}

.Locations-Search-Bar__Wrapper {
  margin-top: 1.5em;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Locations-Search-Bar__Wrapper > input {
  border-radius: 4px;
  border: 1px solid #ced0da;
  box-shadow: none;
  padding: 8px;
  flex-grow: 1;
}

.Locations-Search-Bar__Wrapper > a {
  cursor: pointer;
  padding: 0 10px 0 10px;
}
.Locations-Search-Bar__Wrapper > a img {
  width: 10px;
  height: 10px;
}

.Locations-Search-Bar__Dropdown-Content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 290px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 540px;
  overflow: scroll;
}
.Locations-Search-Bar__Dropdown-Content.active {
  display: block;
}
.Locations-Search-Bar__Dropdown-Content a {
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.Locations-Search-Bar__Dropdown-Content a:hover {
  background: rgb(243, 243, 243);
}

/******* Property Search List *******/

.Properties {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 90vh;
}

.Properties__Results {
  padding: 0 15px;
  flex: 1 0;
  height: 100%;
  overflow-y: scroll;
}

.Properties__Results-Found {
  margin-top: 20px;
  color: #7f8fa4;
  font-size: 13px;
}

.Properties h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 35px;
}

/******* Map Box Container *******/

.Properties__MapContainer {
  width: 100%;
  height: 90vh;
  background-color: rgb(218, 218, 218);
}

.mapboxgl-ctrl-group {
  width: 30px;
  margin: 10px 0 0 10px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  width: 90%;
}

.markerImage {
  width: 26px;
}

.mapbox-point {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #4e4e4e;
}

.mapbox-point::after {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.mapbox-point.selected::after,
.mapbox-point:hover::after {
  background: #ff802c;
}

/******* Media Queries (Responsive) *******/
@media only screen and (min-width: 992px) {
  .Properties__Results {
    flex: 1 0 630px;
  }
}
@media only screen and (min-width: 767px) {
  .Properties__Filter-Container {
    flex-direction: row;
  }
  .Locations-Search-Bar {
    width: 330px;
  }
}

/******* Properties Card Popup (On Mapbox) *******/

.PropertyCardPopup {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 3px solid #ffffff00;
  transition: background-color 300ms ease, box-shadow 300ms ease;
}

.PropertyCardPopup__Details {
  height: auto;
  width: 280px;
  padding-left: 12px;
}

.PropertyCardPopup__Title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  height: 3em;
  line-height: 1.5em;
  overflow: hidden;
}

.PropertyCardPopup__Address {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}

.PropertyCardPopup__Address > img {
  height: 13px;
  margin-right: 8px;
}

.PropertyCardPopup__Address > p {
  font-size: 14px;
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
}

/******* Properties Card (Portrait) *******/

.PropertyCard {
  margin: 14px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  border: 3px solid #ffffff00;
  transition: background-color 200ms ease, box-shadow 200ms ease;
  box-shadow: 0 0 5px 0 rgba(230, 230, 230, 0.5);
  background-color: #fff;
}

.PropertyCard.selected {
  border: 3px solid #646464;
}

.PropertyCard:hover {
  box-shadow: 2px 2px 10px #a0a0a090;
}

.PropertyCard__Title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  height: 3em;
  line-height: 1.5em;
  overflow: hidden;
}

.PropertyCard__Image-Container {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 15px;
}

.PropertyCard__Image-Container > img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.PropertyCard__Details {
  width: 100%;
  height: auto;
  padding: 0;
}

.PropertyCard__Address {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}

.PropertyCard__Address > img {
  height: 13px;
  margin-right: 8px;
}

.PropertyCard__Address > p {
  font-size: 14px;
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
}

.PropertyCard__Row-Highlights {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
}

.PropertyCard__Highlight {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-right: 9px;
  border-radius: 4px;
  padding: 6px 12px;
  background-color: #eff3fa;
  font-size: 12px;
  font-weight: 600;
  color: #27313e;
}

.PropertyCard__Highlight > p {
  font-size: 12px;
}

.PropertyCard__Highlight > img {
  width: 20px;
  height: auto;
  margin-right: 8px;
}

.PropertyCard__Row-Pricing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}

.PropertyCard__Row-Pricing-Item {
  margin-right: 9px;
  border-radius: 2px;
  padding: 8px 14px;
  font-size: 10px;
  font-weight: 600;
  color: #27313e;
  background-color: #f1f1f1;
  white-space: nowrap;
  overflow: hidden;
}

.PropertyCard__Row-Pricing-Item p:first-child {
  color: #7f8fa4;
}

.PropertyCard__Row-Pricing-Item--Profit {
  background-color: #ffe8d9;
}

.PropertyCard__Row-Pricing-Item--Profit p:first-child {
  color: #fe6500;
}

/******* Propertie Card (Landscape) *******/

.PropertyCard--Landscape {
  flex-direction: row;
  padding: 8px;
  margin: 8px 0;
}

.PropertyCard__Image-Container--Landscape {
  width: 280px;
  margin-bottom: 0;
}

.PropertyCard__Details--Landscape {
  height: 200px;
  width: 320px;
  padding-left: 12px;
}

/******* Media Queries (Responsive) *******/
/* @media only screen and (max-width: 992px) {
  .PropertyCard {
    flex-direction: column;
    box-shadow: 0 0 5px 0 rgba(230, 230, 230, 0.5);
    background-color: #fff;
    padding: 10px;
  }

  .PropertyCard__Image-Container {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 5px;
  }

  .PropertyCard__Details {
    width: 100%;
    height: auto;
    padding: 0;
  }
} */

/******* Dashboard *******/

.Dashboard {
  clear: both;
  padding-bottom: 90px;
  overflow: auto;
}

.Dashboard__Results-Found {
  margin-top: 20px;
  color: #7f8fa4;
  font-size: 13px;
}

.Dashboard h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 35px;
}

.Dashboard > div {
  padding-left: 0;
}

/* General Styling */

h1,
h2,
h3,
p {
  font-family: "Open Sans", Helvetica, Arial, serif;
}

a {
  color: #7f8fa4;
  font-size: 14px;
  text-decoration: none;
}

b {
  font-weight: 600;
}

p {
  font-size: 14px;
  color: #27313e;
  text-align: left;
  margin: 0;
}

.backLink img {
  height: 13px;
  margin-right: 13px;
}

.lightText {
  color: #6c7b91;
  font-family: "Helvetica Neue";
}

.midBold {
  font-size: 17px;
  font-weight: 600;
}

/* View Property Styling */

.ViewProperty {
  margin: 18px;
  background-color: white;
}

.ViewProperty .row {
  margin-top: 40px;
}

/******* Top Section *******/

.ViewProperty__Wrapper {
  margin-top: 45px;
}

.ViewProperty__Header {
  margin-top: 40px;
}

.ViewProperty__Header h2 {
  color: #27313e;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.13px;
}

.ViewProperty__Header p {
  color: #27313e;
  font-size: 15px;
  letter-spacing: -0.09px;
}

.ViewProperty__Address {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.ViewProperty__Address > img {
  height: 13px;
  margin-right: 13px;
}

.ViewProperty__Address > p {
  font-size: 15px;
}

.ViewProperty__Row-Highlights {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 22px;
}

.ViewProperty__Row-Highlights > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  border-radius: 4px;
  padding: 8px 15px;
  background-color: #eff3fa;
  font-size: 14px;
  font-weight: 600;
  color: #27313e;
}

/******* View Property Card - Left Panel *******/

.ViewPropertyCard {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.ViewPropertyCard__Separator {
  background-color: #e6e6e6;
  margin: 0 0 0 25px;
}

.ViewPropertyCard__Image-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ViewPropertyCard__Image-Container #slider {
  height: 340px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ViewPropertyCard__Image-Highlight {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 7px 12px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 17px;
  z-index: 1;
  background-color: #ffffff;
}

.ViewPropertyCard__Details {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 0 11px 5px rgba(234, 234, 234, 0.5);
  width: 100%;
  text-align: center;
}

.ViewPropertyCard__Details .CardContainer {
  padding: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ViewPropertyCard__Contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ViewPropertyCard__ContactProfilePic {
  float: left;
}

.ViewPropertyCard__ContactProfile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ViewPropertyCard__ContactButton {
  padding: 8px 35px;
}

.ViewPropertyCard__ContactInfo {
  font-size: 16px;
  margin-left: 15px;
}
.ViewPropertyCard__PriceBreakdown {
  width: 100%;
}

.ViewPropertyCard__PriceBreakdown tr > td:first-child {
  color: #909dad;
  font-size: 15px;
  text-align: left;
}

.ViewPropertyCard__PriceBreakdown tr > td:last-child {
  font-size: 17px;
  font-weight: 600;
  text-align: right;
}

.ViewPropertyCard__PriceBreakdown tr:not(:last-child) td {
  padding-bottom: 10px;
}

.ViewPropertyCard__EstimatedReturns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ViewPropertyCard__EstimatedReturns p {
  color: #fe6500;
}

.ViewPropertyCard__PercentageReturns {
  font-size: 14px;
  border-radius: 2.5px;
  background-color: #fe6500;
  color: #fff;
  padding: 2px 12px;
  margin-right: 22px;
  font-weight: 600;
}

/******* View Property Details - Right Panel *******/

.ViewProperty__Details h3 {
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 17px;
  margin: 0 0 30px 0;
}

.ViewProperty__DetailsTable {
  width: 100%;
  margin: 30px 0;
}

.ViewProperty__DetailsTable tr > td {
  padding-bottom: 15px;
}

.ViewProperty__DetailsTable tr > td:last-child {
  color: #6c7b91;
}

.ViewProperty__Title {
  margin-top: 20px;
}

.ViewProperty-Highlights p {
  color: black !important;
  font-family: "SourceSansPro-Semibold", Helvetica, Arial, serif !important;
  font-size: 11.2px !important;
  letter-spacing: -0.07px !important;
  line-height: 14px !important;
  margin-top: 10px;
  text-align: center;
}

.ViewProperty__Desc {
  margin-bottom: 32px;
  white-space: break-spaces;
}

.ViewProperty__Desc h3 {
  margin-bottom: 22px;
}

.ViewProperty__MapContainer {
  width: 100%;
  height: 360px;
}

/******* Full Screen Slider *******/
.FullScreenSlider {
  width: 100vw;
  height: auto;
  background: rgba(83, 83, 83, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.FullScreenSlider #slider {
  z-index: 11;
}

.FullScreenSlider #slide-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
}

.FullScreenSlider #slide-wrapper > div {
  width: 90%;
  height: 85%;
}

/******* Media Queries (Responsive) *******/
@media only screen and (min-width: 992px) {
  .row.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
  }

  .ViewPropertyCard {
    width: 90%;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
  }
}


.MultiForm {
  margin: 18px;
  background-color: white;
}

.MultiForm__Wrapper {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.MultiForm__Title {
  margin-bottom: 20px;
}

.MultiForm__Title h1 {
  font-size: 22px;
  text-align: center;
}

.MultiForm__Title h2 {
  font-size: 14px;
  text-align: center;
}

.MultiForm__Section {
  width: 100%;
  min-height: 600px;
}

.MultiForm__Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MultiForm__Row-3 > div {
  width: 32%;
}

.MultiForm__Row-2 > div {
  width: 48%;
}

.MultiForm__Upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}
.MultiForm__UploadIcon {
  width: 180px;
  height: auto;
}
.MultiForm__UploadBtn {
  padding: 15px 32px;
}

.MultiForm__ImgWrapper {
  width: 100%;
  object-fit: contain;
  margin: 15px 0;
  position: relative;
}
.MultiForm__ImgWrapper > img {
  width: 100%;
}

.MultiForm__ImgDeleteBtn {
  position: absolute;
  display: flex;
  top: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  background: #e2e2e2;
  transition: transform ease-in 0.1s;
}

.MultiForm__ImgDeleteBtn:hover {
  transform: scale(1.1);
}

/******* Progress Bar *******/
.progressbar-wrapper {
  width: 100%;
  margin: 5px 0 35px 0;
}
.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 24px;
  height: 24px;
  content: "";
  border: 1px solid #cfcfcf;
  display: block;
  text-align: center;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.progressbar li:after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #cfcfcf;
  top: 12px;
  left: -50%;
  z-index: 0;
}

/* Stage - Active */
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  font-weight: bold;
}
.progressbar li.active:before {
  border-color: #5bc33e;
  background: #5bc33e;
}
.progressbar li.active + li:after {
  background-color: #5bc33e;
}
.progressbar li.active span:before {
  width: 12px;
  height: 12px;
  content: "";
  position: absolute;
  top: 6px;
  left: calc(50% - 6px);
  display: block;
  text-align: center;
  border-radius: 50%;
  z-index: 3;
  background-color: #fff;
}

/* Stage - Complete */
.progressbar li.complete:before {
  border-color: #5bc33e;
  background: #5bc33e;
}
.progressbar li.complete + li:after {
  background-color: #5bc33e;
}
.progressbar li img {
  display: none;
}
.progressbar li.complete img {
  display: block;
  position: absolute;
  top: 6px;
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
  z-index: 4;
}

/******* Form Controls *******/
.MultiForm__AutoComplete {
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: #132042;
  font: normal 15px Open Sans, Arial, sans-serif;
  font-size: 15px !important;
  letter-spacing: 0.1px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d2dae2 !important;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(17, 49, 96, 0.06);
  min-height: 36px;
  padding-left: 15px;
}

.MultiForm__AutoComplete-Suggestions {
  display: block;
  width: 100%;
  margin-bottom: 0;
  padding-top: 5px;
  color: #132042;
  font: normal 15px Open Sans, Arial, sans-serif;
  font-size: 15px !important;
  letter-spacing: 0.1px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d2dae2 !important;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(17, 49, 96, 0.06);
  transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  min-height: 36px;
  padding-left: 15px;
}

.form-control {
  min-height: 36px !important;
  border: 1px solid #d2dae2 !important;
}

.control-label {
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: rgba(106, 119, 137, 1);
  line-height: 18px;
  margin-bottom: 7px;
  margin-top: 15px;
}

.MultiForm__btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  margin-right: 140;
}

.MultiForm__back-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #34901b;
  width: 130px;
  margin-right: 40px;
  background: none;
  transition: 0.25s linear;
}
.MultiForm__back-btn:hover {
  background: transparent;
  color: #34901b;
  box-shadow: none;
  transform: translateX(-4px);
}
.MultiForm__back-btn > img {
  width: 20px;
  height: 20px;
}

.MultiForm__btn {
  border-radius: 4px;
  background-color: #34901b;
  padding: 4px 60px;
}

.form-group.required .control-label:after,
label.form-group.required:after {
  content: "*";
  color: red;
}

/******* Media Queries (Responsive) *******/
@media only screen and (min-width: 992px) {
  .progressbar-wrapper {
    width: 90%;
    max-width: 1030px;
  }

  .MultiForm__Section {
    max-width: 720px;
  }

  .MultiForm__btn-container {
    margin-right: 18%;
  }
}

:root {
    --main-color: #5ea3d0;
    --secondary-color: white;
    --main-text-color: #3e5869;
    --secondary-text-color: #b0c7d6;
    --send-message-form: #F5F5F5;
}

.chat-container {
    width: 1191px;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(214,214,214,0.5);
    margin-top: 40px;
}

.message-list {
    box-sizing: border-box;
    width: 100%;
    overflow: scroll;
    background: white;
}

.message {
    margin: 15px 0;
}

.message div:nth-child(1) {
    font-size: 11px;
    color: #3e5869;
    color: var(--main-text-color);
    opacity: 0.9;
    margin-bottom: 6px;
}
.message div:nth-child(2) {
    background: #5ea3d0;
    background: var(--main-color);
    color: white;
    color: var(--secondary-color);
    display: inline;
    padding: 4px 8px;
    border-radius: 8px;
}

.send-message-container {
    width: 1192px;
    background-color: #F9FAFB;
}

.send-message-form .form-control {
    margin-top: 50px;
    padding: 50px;
    box-sizing: border-box;
    border: 1px solid #7CBEF1;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 #D5E8F8;
}
/******* Message Centre *******/
.MessageCentre {
  padding: 20px 0;
}

.MessageCentre h3 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.11px;
}

.MessageCentre__Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1040px;
  height: 90vh;
  margin: auto;
}

.MessagePanel,
.MessageConvo {
  height: 100%;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(230, 230, 230, 0.5);
}

/******* Message Centre - Panel *******/

.MessagePanel {
  flex: 1 0 400px;
  margin-right: 12px;
}

.MessagePanel__Head {
  margin: 20px 25px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MessagePanel__Head > img {
  width: 23px;
  cursor: pointer;
}

.MessagePanel__Convo-List {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 73px);
}

.MessagePanel__Convo-List .ConvoCard:first-child {
  border-top: 1px solid rgb(236, 236, 236);
}

.MessagePanel__Convo-List .ConvoCard:not(first-child) {
  border-bottom: 1px solid rgb(236, 236, 236);
}

/******* Media Queries (Responsive) *******/

@media only screen and (min-width: 767px) {
}

/******* Conversation Card *******/

.ConvoCard {
  padding: 25px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

.ConvoCard.selected {
  background: #f9fff8;
  box-shadow: inset 3px 0 0 #1bbb5b;
}

.ConvoCard:hover {
  background: rgb(240, 240, 240);
}

.ConvoCard__Details {
  margin-left: 15px;
  flex: 1 1;
}

.ConvoCard__Names-Date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.ConvoCard__Names {
  font-size: 15px;
  font-weight: 600;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ConvoCard__Preview {
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

/******* Message Conversation *******/

.MessageConvo {
  width: 100%;
  position: relative;
}

.MessageConvo__Head {
  margin: 20px 25px 30px;
}

.MessageConvo__Separator {
  border-bottom: 1px solid rgb(236, 236, 236);
}

.MessageConvo__Messages,
.MessageConvo__CreateConvo {
  padding: 5px 25px;
}

/******* Create New Conversation *******/

.MessageConvo__Users {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.MessageConvo__Selected-User {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 15px;
  margin: 8px 10px 8px 0;
  padding: 4px 9px;
  background: rgb(236, 236, 236);
}

.MessageConvo__Input-Container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  padding: 0 25px 65px;
}

.MessageConvo__Input-Container > textarea {
  height: 100%;
  width: 100%;
  resize: none;
  border-radius: 5px;
  margin-bottom: 3px;
  border-color: #d8d8d8;
  padding: 7px 11px;
}

/******* Media Queries (Responsive) *******/

@media only screen and (min-width: 767px) {
}

/******* Profile Search Bar *******/

.Profile-Search-Bar {
  border-radius: 3px;
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.Profile-Search-Bar__Wrapper {
  margin-top: 1.5em;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile-Search-Bar__Wrapper > input {
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  box-shadow: none;
  padding: 8px;
  flex-grow: 1;
}

.Profile-Search-Bar__Wrapper > a {
  cursor: pointer;
  padding: 0 10px 0 10px;
}

.Profile-Search-Bar__Wrapper > a img {
  width: 10px;
  height: 10px;
}

.Profile-Search-Bar__Dropdown-Content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 290px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 540px;
  overflow: scroll;
}
.Profile-Search-Bar__Dropdown-Content.active {
  display: block;
}
.Profile-Search-Bar__Dropdown-Content a {
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.Profile-Search-Bar__Dropdown-Content a:hover {
  background: rgb(243, 243, 243);
}

/******* Conversation Card *******/

.MessageBox {
  padding: 25px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.MessageBox__Details {
  margin-left: 15px;
  flex: 1 1;
}

.MessageBox__Name-Date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.MessageBox__Name {
  font-size: 15px;
  font-weight: 600;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

ul.MessageBox__Messages {
  list-style-type: none;
  padding: 0;
}

ul.MessageBox__Messages li {
  padding: 5px 0;
}

